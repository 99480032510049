body {
  background-color: #000;
  margin: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



button {
  cursor: pointer;
}

.grid-subtitle {
  font-size: 14px;
  overflow: visible;
  white-space: normal;
}

.MuiGridListTile-tile {
  background-color: #999;
}